import { fetchWrapper } from "@/helpers";

const ruta = process.env.VUE_APP_ROOT_API;

export default class PuntoVentaService {
  async getPuntoVentaAll() {
    const puntoventas = await fetchWrapper.get(`${ruta}/punto_venta`);
    return puntoventas;
  }

  async sendPundoVenta(datos) {
    return await fetchWrapper.post(`${ruta}/punto_venta`, datos);
  }

  async deletePuntoVentas(id){
    return await fetchWrapper.delete(`${ruta}/punto_venta/${id}`);
  }

  async updatedPuntoVenta(puntoVenta) {
    const actualizado = await fetchWrapper.put(
      `${ruta}/punto_venta/` + puntoVenta.id,
      puntoVenta
    );
    return actualizado;
  }
}